import React from "react";
// import { Link } from "gatsby";

const InteractiveVideo = ({ prodPage = false }) => {
  return (
    <section
      className={`main-container interactive-video primary-bg ${
        prodPage && "prod-page"
      }`}
    >
      <div className={`row top-pad bottom-pad ${prodPage && "bottom-mar"}`}>
        <div className={`column`}>
          <div className='details'>
            <h2
              className='main-title light no-mar'
              // data-aos='fade-in'
              // data-aos-duration='1000'
            >
              Try the interactive video
            </h2>
            <p
              className='light no-mar remove-br-mobile'
              // data-aos='fade-in'
              // data-aos-duration='1000'
            >
              Draw the relevant Life-Saving Rules icons in the red
              <br />
              box (violations) or green box (compliance).
            </p>
          </div>
          <div
            className='button-container'
            // data-aos='fade-in'
            // data-aos-duration='1000'
            // data-aos-delay='200'
          >
            <a
              className='button-link primary large'
              href='https://www.safetyleadersfoundation.com/cluevo/lms/my-learning-tree/'
            >
              Click to start video
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InteractiveVideo;
