import React, { useEffect, useContext, useRef } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
// import { TweenLite, Power3 } from "gsap";

import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import Clients from "../../components/common/Clients";
import InteractiveVideo from "../../components/common/InteractiveVideo";
// import image from "../../assets/img/products/VRLifeSavingRules Main.jpg";
import check from "../../assets/img/icons/check.svg";
// import scene from "../../assets/img/products/VRLifeSavingRules/scene.jpg";
// import workers from "../../assets/img/products/VRLifeSavingRules/workers.jpg";
// import hazards from "../../assets/img/products/VRLifeSavingRules/hazards.jpg";
// import accident from "../../assets/img/products/VRLifeSavingRules/accident.jpg";
// import vrLearningModule from "../../assets/img/products/VRLifeSavingRules/VR Learning Modules.jpg";
// import AOS from "aos";
// import "aos/dist/aos.css";

import { StaticImage } from "gatsby-plugin-image";

const VrLifeSavingRules = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // });

  return (
    <Layout>
      <Seo
        title='VRLifeSavingRules® | VIRSAT - Virtual Reality Safety Training'
        description='VRLifeSavingRules® makes it possible to train & certify large number of workers through a breathtaking experience where you spot emerging risks in workplace.'
      />
      <PageBanner
        title='VRLifeSavingRules<sup>®</sup>'
        normalizeTitle
        subtitle='An ideal way to convince workers'
      />
      <section
        className='main-container single-product hide-container'
        ref={container}
      >
        <div className='row top-pad bottom-pad fd-col less-mar'>
          <div className='column details-container vrlifesavingrules'>
            <div className='details'>
              <p>
                Virtual Reality (VR) is now available for large audiences as
                wireless devices that are affordable, easy-to-use, and deliver a
                high-quality breathtaking experience.
              </p>
              <p>
                VRLifeSavingRules<sup>®</sup> makes it possible to train and
                certify large number of workers through a breathtaking
                experience where you spot emerging risks in the workplace. You
                learn when to intervene and experience what could happen without
                the actual intervention.
              </p>
              <p>
                VRLifeSavingRules<sup>®</sup> is an interactive game that all
                people can play, no matter what your language is. The game works
                with simple visual interactions. You win the game if you know
                the Life-Saving Rules and intervene when needed. Your knowledge
                is rewarded with a certificate.
              </p>
              <ul className='list'>
                <li>
                  <img src={check} alt='Check' /> Easy to use
                </li>
                <li>
                  <img src={check} alt='Check' /> Affordable
                </li>
                <li>
                  <img src={check} alt='Check' /> A breathtaking experience
                </li>
                <li>
                  <img src={check} alt='Check' /> Train large groups and certify
                  them
                </li>
              </ul>
            </div>
            <div className='image-container' data-aos='zoom-in'>
              {/* <img src={image} alt='Pre-job discussions' /> */}
              <StaticImage
                src='../../assets/img/products/VRLifeSavingRules Main.jpg'
                alt='VRLifeSavingRules®'
                placeholder='blurred'
                objectFit
              />
            </div>
          </div>
        </div>
      </section>
      <section className='main-container product-steps secondary-bg'>
        <div className='row top-pad bottom-pad bottom-mar'>
          <div className='column fd-col' data-aos='zoom-in'>
            <div className='step'>1</div>
            <div className='image-container'>
              {/* <img src={scene} alt='This is the scene' /> */}
              <StaticImage
                src='../../assets/img/products/VRLifeSavingRules/scene.jpg'
                alt='This is the scene'
                // placeholder='blurred'
                objectFit
                height='250'
              />
            </div>
            <h3 className='main-subtitle light'>This is the scene</h3>
          </div>
          <div className='column fd-col' data-aos='zoom-in'>
            <div className='step'>2</div>
            <div className='image-container'>
              {/* <img src={workers} alt='These are the workers' /> */}
              <StaticImage
                src='../../assets/img/products/VRLifeSavingRules/workers.jpg'
                alt='These are the workers'
                // placeholder='blurred'
                height='250'
                objectFit
              />
            </div>
            <h3 className='main-subtitle light'>These are the workers</h3>
          </div>
          <div className='column fd-col' data-aos='zoom-in'>
            <div className='step'>3</div>
            <div className='image-container'>
              {/* <img src={hazards} alt='Spot the hazards' /> */}
              <StaticImage
                src='../../assets/img/products/VRLifeSavingRules/hazards.jpg'
                alt='Spot the hazards'
                objectFit
                height='250'
              />
            </div>
            <h3 className='main-subtitle light'>Spot the hazards</h3>
          </div>
          <div className='column fd-col' data-aos='zoom-in'>
            <div className='step'>4</div>
            <div className='image-container'>
              {/* <img src={accident} alt='Experience what will happen' /> */}
              <StaticImage
                src='../../assets/img/products/VRLifeSavingRules/accident.jpg'
                alt='Experience what will happen'
                height='250'
                objectFit
              />
            </div>
            <h3 className='main-subtitle light'>Experience what will happen</h3>
          </div>
        </div>
      </section>
      <section className='main-container product-experience'>
        <div className='row bottom-pad'>
          <div className='column details-image'>
            <div className='details'>
              <h2
                className='main-title'
                data-aos='fade-up'
                data-aos-duration='1000'
              >
                What you will experience
              </h2>
              <p data-aos='fade-up' data-aos-duration='1000'>
                With VRLifeSavingRules<sup>®</sup> you will get a breathtaking
                experience in a Virtual Reality workplace, where an actual
                incident is waiting to happen. The incident is related to one of
                the Life-Saving Rules.
              </p>
              <p data-aos='fade-up' data-aos-duration='1000'>
                VRLifeSavingRules<sup>®</sup> shows an actual situation where
                well intended workers try hard to do their job. Sadly, the
                circumstances change and the workers are about to face an
                incident, unless you spot the hazards and intervene.
              </p>
              <p data-aos='fade-up' data-aos-duration='1000'>
                You will be able to explore the work scene and make
                interventions. You will also see ‘what could have happened’ if
                you didn’t intervene. By selecting the applicable Life-Saving
                Rule you can identify how the lives of the workers could have
                been saved.
              </p>
              <h2
                className='main-title second'
                data-aos='fade-up'
                data-aos-duration='1000'
              >
                VR headset, how it works
              </h2>
              <p data-aos='fade-up' data-aos-duration='1000'>
                The customer decides how many VRLifeSavingRules<sup>®</sup> sets
                are required. These come ready to use – ‘Plug and Play’. Your
                VRLifeSavingRules<sup>®</sup> sets are kept up-to-date by Safety
                Leaders Foundation at all times.
              </p>
              <p data-aos='fade-up' data-aos-duration='1000'>
                This is done automatically whenever the headset is within reach
                of Wifi. If required a headsets management system can be
                provided. Trainees can get a certificate when successful in
                playing the game.
              </p>
            </div>
            <div
              className='image-container'
              data-aos='zoom-in'
              data-aos-duration='1000'
            >
              {/* <img src={vrLearningModule} alt='VR Learning Modules' /> */}
              <StaticImage
                src='../../assets/img/products/VRLifeSavingRules/VR Learning Modules.jpg'
                alt='VR Learning Modules'
                objectFit
              />
            </div>
          </div>
        </div>
      </section>
      <Clients />
      <InteractiveVideo />
    </Layout>
  );
};

export default VrLifeSavingRules;
