import React, { useEffect, useContext, useRef } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { TweenLite, Power3 } from "gsap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const PageBanner = ({
  title,
  subtitle = "",
  normalizeTitle = false,
  customClass,
  smallTitle=false,
  useDiv=false,
}) => {
  const data = useStaticQuery(graphql`
    query {
      default: file(
        relativePath: { eq: "pages/banner/VIRSAT Page Banner.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      animation: file(
        relativePath: { eq: "pages/banner/animation-banner.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      contact: file(relativePath: { eq: "pages/banner/contact-banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      product: file(relativePath: { eq: "pages/banner/products-banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);

  const state = useContext(GlobalStateContext);
  let bannerTitle = useRef(null);
  let bannerSubtitle = useRef(null);
  let container = useRef(null);

  let imgSrc = getImage(data.default.childImageSharp);

  switch (customClass) {
    case "animation":
      imgSrc = getImage(data.animation.childImageSharp);
      break;
    case "contact":
      imgSrc = getImage(data.contact.childImageSharp);
      break;
    case "product":
      imgSrc = getImage(data.product.childImageSharp);
      break;
    default:
      break;
  }

  useEffect(() => {
    if (!state.showPageLoading) {
      TweenLite.to(container, 1.1, {
        opacity: 1,
        y: 0,
        scaleY: 1,
        ease: Power3.easeInOut,
        // delay: 1,
      });
      TweenLite.to(bannerTitle, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeInOut,
        delay: 1,
      });
      if (subtitle) {
        TweenLite.to(bannerSubtitle, 1, {
          opacity: 1,
          y: 0,
          ease: Power3.easeInOut,
          delay: 1.3,
        });
      }
    }
  }, [state.showPageLoading, subtitle]);

  function createMarkup() {
    return {
      __html: title,
    };
  }

  return (
    <div
      className={`main-container page-banner ${customClass ? customClass : ""}`}
      ref={(el) => (container = el)}
    >
      <div className='banner-image'>
        <GatsbyImage image={imgSrc} alt={title} />
      </div>
      <div className='row'>
        <div className='column center'>
          {useDiv ? (
            <div
              className={`main-title light no-mar ${normalizeTitle ? "normal":''} ${smallTitle ? "small":''}`}
              dangerouslySetInnerHTML={createMarkup()}
              ref={(el) => (bannerTitle = el)}
            />
          ):(
            <h1
              className={`main-title light no-mar ${normalizeTitle ? "normal":''} ${smallTitle ? "small":''}`}
              dangerouslySetInnerHTML={createMarkup()}
              ref={(el) => (bannerTitle = el)}
            />
          )
          }
          {subtitle && (
            <p className='no-mar light' ref={(el) => (bannerSubtitle = el)}>
              {subtitle}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
