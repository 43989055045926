import React, { useEffect, useContext, useRef } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import clientImg from "../../assets/img/pages/clients.jpg";

const Clients = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);
  return (
    <section
      className='main-container page-clients hide-container'
      ref={container}
    >
      <div className='row bottom-pad'>
        <div className='left-overlay'></div>
        <div className='column demo-clients'>
          {/* <StaticImage
            src='../../assets/img/pages/clients.jpg'
            alt='Clients'
            placeholder='blurred'
            objectFit
          />
          <StaticImage
            src='../../assets/img/pages/clients.jpg'
            alt='Clients'
            placeholder='blurred'
            objectFit
          /> */}
          <img src={clientImg} alt='Clients' />
          <img src={clientImg} alt='Clients' />
        </div>
        <div className='right-overlay'></div>
      </div>
    </section>
  );
};

export default Clients;
